<template>
  <!-- 审批模板 -->
  <approval-form
    :title="formTitle"
    :open="open"
    :approvalRecord="approvalRecord"
    :billImageUrl="form.billImageUrl"
    :action="action"
    @onClose="onClose"
    @ok="handleOk"
  >
    <!-- 审批头部内容 -->
    <div slot="approvalHeader">
      <a-row v-for="(item, index) in rechargeInfo" :key="index">
        <a-col :span="6"> </a-col>
        <a-col :span="6"> {{ item.label }}: </a-col>
        <a-col :span="6">
          {{ item.value }}
        </a-col>
        <a-col :span="6"> </a-col>
      </a-row>
    </div>
  </approval-form>
</template>
<script>
import { getOilAccountRecord } from '@/api/iot/oilAccountRecord'
import ApprovalForm from '../../ApprovalForm'
export default {
  name: 'RechargeForm',
  components: {
    ApprovalForm
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    approvalRecord: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      open: this.isShow,
      form: {},
      applicantName: '',
      rechargeInfo: {}
    }
  },
  computed: {
    formTitle() {
      if (this.action === 'reject') {
        return '充值申请撤回'
      } else if (this.action === 'approval') {
        return '充值审批'
      } else {
        return '充值详情'
      }
    }
  },
  watch: {},
  mounted() {
    console.log('action', this.action)
    this.handleGetRechargeInfo(this.approvalRecord)
  },
  methods: {
    onClose() {
      this.open = false
      this.$emit('componentClose')
    },
    handleOk() {
      this.$emit('ok')
    },
    handleGetRechargeInfo(record) {
      console.log('record', record)
      /** 获取充值信息 */
      getOilAccountRecord(record.approvalKindId).then(response => {
        this.form = response.data
        this.rechargeInfo = [
          { label: '审批编号', value: this.approvalRecord.approvalNo },
          { label: '充值卡号', value: this.form.cardNo },
          { label: '此卡当前总余额（元）', value: this.form.balanceMainCard },
          { label: '此卡当前未划拨余额（元）', value: this.form.balanceAvailable },
          { label: '充值发起人', value: this.form.createBy },
          { label: '发起时间', value: this.form.createTime },
          { label: '充值金额', value: this.form.amount },
          { label: '备注说明', value: this.form.comment }
        ]
      })
      this.open = true
    }
  }
}
</script>
